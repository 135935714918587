<template>
  <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom" style="min-height: 20rem">
    <div style="font-weight: 600;color: #4ca2ff;font-size: 1.6rem">最新版本:{{remote}}</div>
    <div style="font-weight: 600;color: #4ca2ff;font-size: 1.6rem">当前版本:{{local}}</div>
    <template v-if="remote != local">
      <div class="y-desc" style="margin-top: 2rem;color: red">更新前做好站点数据备份</div>
      <el-button @click="update" style="margin-top: 1rem" type="primary">立即更新</el-button>
    </template>
    <div v-else style="margin-top: 2rem;" class="y-desc">当前已是最新版本</div>
  </div>
</template>

<script>
export default {
  name: "sys",
  data(){
    return{
      local:"",
      remote:"",
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$api.sys.manager.versionRemote().then(res=>{
        this.local = res.local;
        this.remote = res.remote;
      })
    },
    update(){
      this.$loading({text:"系统升级中请稍候..."})
      this.$api.sys.manager.versionUpdate().then(()=>{
        setTimeout(()=>{
          this.$loading().close()
          location.reload();
        },3000)
      })
    },
  }
}
</script>

<style scoped>

</style>